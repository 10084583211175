/* eslint-disable @wordpress/dependency-group */
import $ from 'jquery';

import './inc/responsive-menus';
import './inc/menu';
import './inc/minicart';
import './inc/product';
import './inc/archive';
import './inc/checkout';
import './inc/footer';
import './inc/default-variation';