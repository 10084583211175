const $ = jQuery;

$(document).ready(function () {

    const referrer = document.referrer;
    const hasReloaded = sessionStorage.getItem('hasReloaded');

    if (!hasReloaded && (referrer.includes('produkt') || location.pathname.includes('heimeligshop'))) {
        sessionStorage.setItem('hasReloaded', 'true');
        setTimeout(() => location.reload(), 700);
    }
    

    var typingTimer;
    var doneTypingInterval = 2000;
    if ($('#billing_postcode').val()) {
        $('.woocommerce-shipping-totals #shipping_method').css('display', 'flex');
    }
    $('#billing_postcode').on('input', function () {

        clearTimeout(typingTimer);
        typingTimer = setTimeout(function () {
            var currentPostcode = $('#billing_postcode').val();
            if (shippingPostcodes.postcodes.includes(currentPostcode)) {
                $('.woocommerce-shipping-totals #shipping_method').css('display', 'flex');
                $('.custom-shipping-message').addClass('no-shipping-methods');
            } else if (currentPostcode) {
                $('.custom-shipping-message').removeClass('no-shipping-methods');
                $('.woocommerce-shipping-totals #shipping_method li').css('display', 'none');
            } else {
                $('.woocommerce-shipping-totals #shipping_method').css('display', 'none');
                $('.custom-shipping-message').addClass('no-shipping-methods');
            }
        }, doneTypingInterval);
    });

    if ($('.bank-details-wrapper').length) {
        var p = $('.bank-details-wrapper > p').wrap('<div class="bank-info-p"></div>');
        $('<h2 class="wc-bacs-bank-details-heading">Hinweis:</h2>').prependTo('.bank-info-p');
    }

    if (typeof checkoutVars !== 'undefined') {
        $('#billing_postcode').on('input', function () {
            setTimeout(function () {
                if (!!checkoutVars.has_pflegebetten) {
                    var listItems = $('#shipping_method li');
                    if (listItems.length > 1) {
                        $('#shipping_method li:nth-child(2)').hide();
                        $('#shipping_method li:nth-child(3)').hide();
                    }
                }
            }, 1000);
        });

        if (!!checkoutVars.has_pflegebetten) {
            var firstShippingMethod = $('#shipping_method li:first-child input');
            firstShippingMethod.val('270.25');
            firstShippingMethod.prop('checked', true);
        }
    }
});