document.addEventListener('DOMContentLoaded', function () {
	document.body.addEventListener('click', function (event) {
		if (
			event.target.matches('.open-submenu-container') ||
			event.target.matches('.open-submenu')
		) {
			let allSubMenus = document.querySelectorAll('.sub-menu');
			allSubMenus.forEach(function (menu) {
				menu.style.display = 'none';
			});

			let parent = event.target.parentElement;

			if (parent.matches('.open-submenu-container')) {
				parent = parent.parentElement;
			}

			let subMenu = parent.querySelector('.submenu');
			if (subMenu) {
				subMenu.style.display =
					subMenu.style.display === 'none' ||
					subMenu.style.display === ''
						? 'block'
						: 'none';
			}
		}
	});
});

const menuBar = document.querySelector('.menu-bar');
const logoBar = document.querySelector('.logo-bar');
const stickyPosition = menuBar.offsetTop;
window.addEventListener('scroll', function () {
	if (window.innerWidth > 1023) {
		if (window.pageYOffset > stickyPosition) {
			menuBar.classList.add('sticky');
			logoBar.classList.add('sticky');
		} else {
			menuBar.classList.remove('sticky');
			logoBar.classList.remove('sticky');
		}
	}
});

var modalOverlay = document.getElementById('modal-overlay');
var modalForm = document.querySelector('#modal-overlay .modal-form');
var closeModalBtn = document.querySelector('#modal-overlay .frm_close');
var openModalBtn = document.querySelector('#modal-overlay .enquiry-button');
if (openModalBtn) {
	if (
		document.querySelectorAll('#modal-overlay .validation_message').length >
		0
	) {
		modalOverlay.style.display = 'flex';
	}

	openModalBtn.addEventListener('click', function () {
		modalOverlay.style.display = 'flex';
		document.body.style.overflow = 'hidden';
	});

	closeModalBtn.addEventListener('click', closeModal);

	modalOverlay.addEventListener('click', function (event) {
		if (event.target === modalForm || modalForm.contains(event.target)) {
			return;
		}
		closeModal();
	});
}

function closeModal() {
	modalOverlay.style.display = 'none';
	document.body.style.overflow = '';
}
