jQuery(document).ready(function ($) {
    let lowestValue = null;
    let lowestSwatch = null;

    // Find all Swatchly variation options
    let $swatches = $('.swatchly-type-wrap .swatchly-swatch');

    if ($swatches.length) {
        $swatches.each(function () {
            let value = parseFloat($(this).attr('data-attr_value')); // Extract numeric value
            let numericValue = parseFloat(value);

            if (isNaN(numericValue)) {
                return false; 
            }

            if (!isNaN(value) && (lowestValue === null || value < lowestValue)) {
                lowestValue = value;
                lowestSwatch = $(this);
            }
        });

        // If a lowest swatch is found, trigger a click event to select it
        if (lowestSwatch !== null) {
            lowestSwatch.trigger('click');
        }
    }
});
